var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CDropdown",
    {
      staticClass: "c-header-nav-items",
      attrs: { inNav: "", placement: "bottom-end", "add-menu-classes": "pt-0" },
      scopedSlots: _vm._u([
        {
          key: "toggler",
          fn: function() {
            return [
              _c("CHeaderNavLink", [
                _c(
                  "div",
                  { staticClass: "c-avatar" },
                  [
                    _c("CIcon", {
                      staticClass: "c-avatar-img ",
                      attrs: { name: "cil-user", size: "3xl" }
                    })
                  ],
                  1
                )
              ])
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "CDropdownItem",
        {
          on: {
            click: function($event) {
              return _vm.toChagePassword()
            }
          }
        },
        [
          _c("CIcon", { attrs: { name: "cil-user" } }),
          _vm._v("パスワード変更 ")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }