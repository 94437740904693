var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CSidebar",
    {
      attrs: { fixed: "", minimize: _vm.minimize, show: _vm.show },
      on: {
        "update:show": function(value) {
          return _vm.$store.commit("set", ["sidebarShow", value])
        }
      }
    },
    [
      _c(
        "CSidebarBrand",
        { staticClass: "d-md-down-none", attrs: { to: "/pages/notice/list" } },
        [_c("div", { on: { click: _vm.brandClick } })]
      ),
      _c("CRenderFunction", {
        attrs: { flat: "", "content-to-render": _vm.menus }
      }),
      _c("CSidebarMinimizer", {
        staticClass: "d-md-down-none",
        nativeOn: {
          click: function($event) {
            return _vm.$store.commit("set", ["sidebarMinimize", !_vm.minimize])
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }