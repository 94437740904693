<template>
  <CSidebar
      fixed
      :minimize="minimize"
      :show="show"
      @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" to="/pages/notice/list">
<!--      <CIcon-->
<!--          class="c-sidebar-brand-full"-->
<!--          name="logo"-->
<!--          size="custom-size"-->
<!--          :height="35"-->
<!--          viewBox="0 0 556 134"-->
<!--      />-->
<!--      <CIcon-->
<!--          class="c-sidebar-brand-minimized"-->
<!--          name="logo"-->
<!--          size="custom-size"-->
<!--          :height="35"-->
<!--          viewBox="0 0 110 134"/>-->
<!--      <div @click="brandClick">-->
<!--        オキシコネクト-->
<!--      </div>-->
      <div @click="brandClick">

      </div>

    </CSidebarBrand>


    <!--    {{nav}}-->
    <CRenderFunction flat :content-to-render="menus"/>
    <CSidebarMinimizer
        class="d-md-down-none"
        @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>import {freeSet} from '@coreui/icons'
import {_NAV, NODE_TREE} from './_nav'


export default {
    name: 'TheSidebar',
    freeSet,
    computed: {
        menus() {
            // console.log("TheSideBar menus");
            let nav = _NAV();
            nav[0]._children = NODE_TREE(nav[0]._children,process.env.VUE_APP_MODE,this.$store.state.user.role || 'ALL',this.$store.state.user.patientRegistable)
            return nav;
        },
        show() {
            return this.$store.state.sidebarShow
        },
        minimize() {
            return this.$store.state.sidebarMinimize
        }
    },methods:{
      brandClick:function(){
        location.href="/#/pages/notice/list";
      }
  }
}
</script>
<style>
  .router-link-exact-active.c-active.c-sidebar-nav-link {
    background-color: #59718d;
  }

  .c-sidebar-nav-dropdown-toggle {
    font-weight: bold;
  }

  .c-sidebar-nav-dropdown-items {
    /*margin-left:5px;*/
    opacity: 0.6;
  }

  /*.c-sidebar{
    display:none;
  }*/
</style>
