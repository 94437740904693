<template>
  <CDropdown
          inNav
          class="c-header-nav-items"
          placement="bottom-end"
          add-menu-classes="pt-0"

  >
    <template #toggler>
      <CHeaderNavLink>
        <div class="c-avatar">
<!--          <img-->
<!--                  src="img/TNS.png"-->
<!--                  class="c-avatar-img "-->
<!--          />-->
          <CIcon name="cil-user" class="c-avatar-img " size="3xl"/>

        </div>
      </CHeaderNavLink>

    </template>
    <!--            <CDropdownHeader tag="div" class="text-center" color="light">-->
    <!--              <strong>Account</strong>-->
    <!--            </CDropdownHeader>-->
    <!--            <CDropdownItem @click="setNavMode">-->
    <!--              <CIcon name="cil-bell"/> Updates-->
    <!--              <CBadge color="info" class="ml-auto">{{ itemsCount }}</CBadge>-->
    <!--            </CDropdownItem>-->
    <!--            <CDropdownItem>-->
    <!--              <CIcon name="cil-envelope-open" /> Messages-->
    <!--              <CBadge color="success" class="ml-auto">{{ itemsCount }}</CBadge>-->
    <!--            </CDropdownItem>-->
    <!--            <CDropdownItem>-->
    <!--              <CIcon name="cil-task" /> Tasks-->
    <!--              <CBadge color="danger" class="ml-auto">{{ itemsCount }}</CBadge>-->
    <!--            </CDropdownItem>-->
    <!--            <CDropdownItem>-->
    <!--              <CIcon name="cil-comment-square" /> Comments-->
    <!--              <CBadge color="warning" class="ml-auto">{{ itemsCount }}</CBadge>-->
    <!--            </CDropdownItem>-->
    <!--            <CDropdownHeader-->
    <!--              tag="div"-->
    <!--              class="text-center"-->
    <!--              color="light"-->
    <!--            >-->
    <!--              <strong>Settings</strong>-->
    <!--            </CDropdownHeader>-->

    <CDropdownItem @click="toChagePassword()">
      <CIcon name="cil-user"/>パスワード変更
    </CDropdownItem>
    <!--            <CDropdownItem>-->
    <!--              <CIcon name="cil-settings" /> Settings-->
    <!--            </CDropdownItem>-->
    <!--            <CDropdownItem>-->
    <!--              <CIcon name="cil-dollar" /> Payments-->
    <!--              <CBadge color="secondary" class="ml-auto">{{ itemsCount }}</CBadge>-->
    <!--            </CDropdownItem>-->
    <!--            <CDropdownItem>-->
    <!--              <CIcon name="cil-file" /> Projects-->
    <!--              <CBadge color="primary" class="ml-auto">{{ itemsCount }}</CBadge>-->
    <!--            </CDropdownItem>-->
    <!--            <CDropdownDivider/>-->
    <!--    <CDropdownItem>-->
    <!--      <CIcon name="cil-shield-alt" /> Lock Account-->
    <!--    </CDropdownItem>-->
<!--    <CDropdownItem @click="logout">-->
<!--      <CIcon name="cil-lock-locked" /> <span>Logout</span>-->
<!--    </CDropdownItem>-->
  </CDropdown>
</template>

<script>
import axios from "axios";

export default {
    name: 'TheHeaderDropdownAccnt',
    data () {
      return {
        itemsCount: 42
      }
    },methods:{
      toChagePassword(){
        this.$router.push({path: "/pages/userInfo"});
      },
      logout(){
        this.axios.post('/api/user/logout',
                {
                  token:this.$route.params.activeToken
                }
                , {
                  headers: {'Content-Type': 'application/json'}
                }
        ).then(response => {
          // console.log('response', response);

        }).catch(error => {
          // console.log('error', error);
          console.log('error', error.response.data);
        }).finally(() => {
          window.location = process.env.VUE_APP_CLIENT_URL;
        });
      },setNavMode(){
        this.$store.commit("setNavMode", "user");
      }
    }
  }
</script>

<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }
</style>